import { cva } from 'class-variance-authority';

export const textAreaContainer = cva([
  'transition',
  'flex',
  'items-center',
  'justify-between',
  'resize-none',
  'min-h-14',
  'rounded-lg',
  'p-4',
  'rac-invalid:focus-within:ring-negative-400',
  'rac-invalid:focus:ring-negative-400',
  'rac-invalid:focus-within:ring-1',
  'rac-invalid:focus:ring-1',
  'rac-invalid:ring-1',
  'rac-invalid:ring-1',
  'rac-disabled:placeholder:text-400',
  'rac-disabled:description-icon:text-grey-400',
]);
